export const URL_PATTERNS_TO_HIDE_LEFT_MENU = [/\/tasks\/global-settings\/template/, /\/notices\/global-settings\/template/];

export const SUB_NAV_TEMPLATES = [
  /\/tasks\/global-settings/,
  /\/templates\/tasks/,
  /\/global-settings\/tax-return/,
  /\/communications\/global-settings\/templates/,
  /\/notices\/global-settings/,
  /\/admin\/global-settings\/service-templates/,
  /\/letters\/global-settings\/templates/,
  /\/letters\/global-settings\/boilerplate/,
  /\/files\/global-settings\/folder-templates/,
  /\/notifications\/global-settings\/messages/,
];

export const NAV_CONTENT_STYLE = {
  marginLeft: "318px",
  width: "calc(100% - 318px)",
  padding: "24px 16px",
  height: "calc(100vh - 56px)",
};

export const urlPatternMatch = (url, patterns) => patterns.some((regex) => regex.test(url));

export const shouldHideLeftMenu = () => {
  const { hash } = window.location;
  const patterns = [...URL_PATTERNS_TO_HIDE_LEFT_MENU, ...SUB_NAV_TEMPLATES];
  return urlPatternMatch(hash, patterns);
};

export const isTemplatePage = () => {
  const { hash } = window.location;
  const patterns = [...URL_PATTERNS_TO_HIDE_LEFT_MENU, ...SUB_NAV_TEMPLATES];
  return urlPatternMatch(hash, patterns);
};
